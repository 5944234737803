<style>
  .app-square {
    width: 10px;
    height: 10px;
    background-color: rgb(38, 38, 38);
    transform: rotate(45deg);
  }

  .app-line {
    height: 2px;
    min-width: 100px;
    max-width: 450px;
    width: 100%;
    background-color: black;
  }

  .app-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 20px 20px;
  }

</style>
<div class="app-divider">
  <div class="app-square"></div>
  <div class="app-line"></div>
  <div class="app-square"></div>
</div>