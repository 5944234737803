import { Component } from '@angular/core';
import { productList } from '../../app.config';
import { CommonModule } from '@angular/common';
import { wait } from '../../async';

@Component({
  selector: 'products-page',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './products.component.html',
  styleUrl: './products.component.css',
})
export class ProductsComponent {
  products: Array<string> = [];

  ngOnInit() {
    const addItem = (i = 0, ms = 80) => {
      i >= productList.length ? undefined : 
        wait(ms)
          .then(() => this.products.push(productList[i]))
          .then(() => addItem(i + 1, ms * 0.8));
    };
    addItem(0);
  }
}