
import { Component } from '@angular/core';
import { CircleComponent } from '../../circle/circle.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DividerComponent } from '../../divider/divider.component';

@Component({
  selector: 'applications-page',
  standalone: true,
  imports: [CircleComponent, RouterLink, DividerComponent],
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.css',
})
export class ApplicationsComponent {
  toggle: { [index: string]: any } = {
    nectares: false,
    panaderia: false,
  };

  toggleProductlist(name: string) {
    this.toggle[name] = this.toggle[name] ? false : true;
  }
}