
import { Component } from '@angular/core';
import { LogoComponent } from '../logo/logo.component';
import { contact } from '../app.config';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [LogoComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
})
export class FooterComponent {
  year = new Date().getFullYear();
  social = contact;
}