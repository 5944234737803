
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { CommonModule } from '@angular/common';  
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import { Server } from '../../server/server';

const { log } = console;



/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'contact-page',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatButtonModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css',
})
export class ContactComponent {
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  messageFormControl = new FormControl('', [Validators.required]);
  titleFormControl = new FormControl('', [Validators.required]);
  submitted = false;
  // contactForm = new FormControl({
  //   messageFormControl: new FormControl('', [Validators.required]),
  //   emailFormControl: new FormControl('', [Validators.required, Validators.email])
  // });
  matcher = new MyErrorStateMatcher();
  server = new Server();

  onSubmitt() {
    log('Clicking onSubmitt', this, this.emailFormControl, this.messageFormControl);
    if (this.emailFormControl.valid && this.messageFormControl.valid && this.titleFormControl.valid) {
      const message = this.messageFormControl.value as string;
      const email = this.emailFormControl.value as string;
      const subject = this.titleFormControl.value as string;
      log('valid form', message, email);
      this.server
        .contact(message, email, subject)
        .then((response) => { 
          this.submitted = true; 
        }).catch((reason) => log('something failed', reason));
    }
    // log('Clicking ', this, this.contactForm);
  }
}