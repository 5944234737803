
import { Component } from '@angular/core';

@Component({
  selector: 'lab-page',
  standalone: true,
  imports: [],
  templateUrl: './lab.component.html',
  // styleUrl: './lab.component.css',
})
export class LabComponent {

}