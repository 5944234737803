
<style>
  .circle {
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  
  .circle:hover img { 
    filter: brightness(0.3);
  }

  .circle h3 {
    color: white;
    z-index: 3;
    margin: 0px;
    line-height: 20px !important;
    padding: 5px;
  }
  
  .circle img {
    position: absolute;
    filter: brightness(0.7);
    border-radius: 50%;
    border: 2px solid black;
  }

</style>
<div class="circle" [ngStyle]="styles">
  <img src={{src}} [ngStyle]="imageStyles">
  <h3>{{title}}</h3>
</div>