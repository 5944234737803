<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style> 
  .category-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;;
    align-items: center;;
    margin: 0px;
    padding: 0px;
  }

  .category-list > li {
    list-style-type: none;
    cursor: pointer;
    margin: 10px;
  }

  .category-list > li:hover {
    color: black;
  }

  .category-list > li.open {
    list-style-type: disclosure-open;
    color: black;
  }

  li.open .product-list {
    display: flex;;
    align-items: center;
  }

  li.open > ul {
    display: block;
  }

  .product-list {
    display: flex;
    list-style-type: circle;
    display: none;
    cursor: default;
    margin: 10px 0px 10px 0px;
  }

  .product-list img {
    border-radius: 50%;
    max-height: 100px;
    margin: 10px;
    border: 3px solid black;
    filter: brightness(0.9);
  }

  h3 {
    margin-top: 35px;
    text-align: center;
  }

</style>

<main class="main page">
  <section class="content">
    <h2 i83n>Desde el concepto hasta su finalización</h2>
    <p i83n>Aromaven Sabores especialistas en desarrollar Esencias para alimentos sólidos y líquidos en el área de alimentos e industria farmacéutica de salud. Trabajamos directamente con los clientes para superar las dificultades técnicas y prestar apoyo durante el proceso de desarrollo y aplicación. </p>
    <p i83n>Aromaven utiliza un enfoque de cuatro vertientes para el desarrollo de aplicaciones: 1. Conocimiento del mercado, 2. Las tendencias del mercado, 3. Tecnología, 4. Funcionalidad - con el fin de crear conceptos innovadores para nuestros clientes </p>
    <h3 i83n>Nuestra Gama de aplicaciones incluye</h3>
    <app-divider></app-divider>    
    <ul class="category-list">
      <li>
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/aceite.webp" title="Aceites saborizados y blends."></app-circle>
        </a>
      </li>
      <li i83n>
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/alcohol.png" title="Bebidas alcohólicas y sin alcohol."></app-circle>
        </a>
      </li>
      <li i83n>
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/jugos.jpg" title="Néctares y jugos naturales."></app-circle>
        </a>
      </li>
      <li i83n>
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/leche.webp" title="Leche y productos lácteos."></app-circle>
        </a>
      </li>
      <li i83n >
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/snacks.jpg" title="Panadería y snacks salados."></app-circle>
        </a>
      </li>
      <li i83n>
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/sopas.webp" title="Sopas, salsas y condimentos."></app-circle>
        </a>
      </li>
      <li i83n>
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/leche.png" title="Productos de confitería y chucherías."></app-circle>
        </a>
      </li>
      <li i83n>
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/farmaceutical.jpg" title="Farmacéutica Salud y cuidado bucal."></app-circle>
        </a>
      </li>
      <li i83n>
        <a i83n routerLink="/products" routerLinkActive="active" ariaCurrentWhenActive="page">
          <app-circle src="/assets/products/balanceados.webp" title="Alimentos balanceados para animales."></app-circle>
        </a>
      </li>
    </ul>
  </section>
</main>
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder  * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
