<style>
  .fa-heart {
    color: red;
  }

  footer {
    min-height: 50px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  p.small {
    margin: 0px;
    font-size: 12px!important;
  }

  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .contact-list li {
    padding: 10px;
    list-style-type: none;
  }

  .contact-list a {
    color: white;;
  }

  .contact-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    background-color: #f9f4fe;
    color: rgb(87, 87, 87);
  }

  .contact-list-container a {
    color: rgb(87, 87, 87);
  }
  
  .copyright {
    padding: 10px;
  }

  aromaven-logo {
    margin-bottom: 15px;
  }

  @media screen and (min-width: 650px) {
    ul.contact-list {
      display: flex;
      justify-content: center;
    }

    .contact-list {
      margin: 0px;
    }

  }

  @media screen and (max-width: 650px) {
    footer {
      min-height: 100px;
    }

    .contact-list-container ul li {
      display: flex!important;
      align-items: center;
    }

    .contact-list-container ul {
      padding: 0px;
    }

    aromaven-logo {
      margin-top: 15px;
    }
  }

</style>

<footer class="footer page">
  <div class="column">
    <section class="contact-list-container column">
      <aromaven-logo hideSince="false" hideRIF="false" size="150"></aromaven-logo>
      <ul class="contact-list">
        <li>
          <a href="mailto:{{social.email}}" >
            <i class="fa-solid fa-envelope" ></i>
            {{social.email}}
          </a>
        </li>
        <li>
          <i class="fa fa-fax" aria-hidden="true"></i>
          {{social.fax}}
        </li>
        <li>
          <i class="fa fa-phone-square" aria-hidden="true"></i>
          {{social.phone}}
        </li>
        <li>
          <a href={{social.twitterLink}}>
            <i class="fa-brands fa-square-x-twitter"></i>
            Twitter
          </a>
        </li>
        <li>
          <a href={{social.instagramLink}}>
            <i class="fa-brands fa-square-instagram"></i>
            Instagram
          </a>
        </li> 
        <li>
          <a href={{social.facebookLink}}>
            <i class="fa-brands fa-square-facebook"></i>
            Facebook
          </a>
        </li> 
      </ul>
    </section>
    <section class="copyright">
      <p i83n class="small">Copyright © Aromaven Sabores, C.A. RIF: J-29546429-0 <time>{{year}}</time>. Todos los derechos reservados. Diseñado y desarrollado con <i class="fa-solid fa-heart"></i>.</p>
    </section>
  </div>
</footer>