<style>
  :host {
    --nav-height: 56px;
    --nav-background-color: white;
  }

  ul {
    padding: 0px;
    margin: 0px;
  }

  ul li {
    list-style: none;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .sidenav {
    display: none;
  }

  .sidenav-open {
    position: absolute;
    left: 40px;
    top: var(--nav-height);
    height: calc(100vh - var(--nav-height));
    display: flex;
    width: calc(100% - 40px);
    max-width: calc(2000px - 40px);
    background-color: var(--nav-background-color);
    z-index: 3;
  }

  .overlay {
    position: absolute;
    top: var(--nav-height);
    display: flex;
    width: 100%;
    max-width: calc(2000px + 40px);
    height: calc(100vh - var(--nav-height));
    background-color: rgba(15, 15, 15, 0.226);
    z-index: 1;
  }

  .sidenav-list {
    position: relative;
    width: 100%;
    max-width: 700px;
  }

  .sidenav-list li a {
    /* padding: 10px; */
    display: flex;
    /* justify-content: ; */
    align-items: center;
    height: 50px;
    width: 100%;
    max-width: 700px;
  }

  .sidenav-list li a:first-child {
    border-right: 2px solid white;
  }

  .sidenav-list li a {
    color: black;
    /* background-color: blue;; */
    border-bottom: 1px solid #cbcbcb;
    padding-left: 10px;
  }

  .active {
    border-left: 3px solid var(--color-brand)!important;
  }

  .sidenav-icons-list {
    display: flex!important;
    width: 100%;
  }

  .sidenav-icons-list li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #ec6060;
    color: white;
    font-size: 2em;
  }

  .sidenav-icons-list li a {
    justify-content: center;
    display: flex;
    color: white;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 650px) {
    .sidenav, .overlay {
      display: none;
    }
  }
  
</style>

<section class="sidenav" [class.sidenav-open]="isOpen">
  <ul class="sidenav-list">
    <li>
      <ul class="sidenav-icons-list">
        <li>
          <a href="mailto:{{social.email}}" >
            <i class="fa-solid fa-envelope" ></i>
          </a>
        </li>
        <li>
          <a href={{social.twitterLink}}>
            <i class="fa-brands fa-square-x-twitter"></i>
          </a>
        </li>
      </ul>
    </li>
    <li>
      <a i83n routerLink="home" routerLinkActive="active" ariaCurrentWhenActive="page">Inicio</a>
    </li>
    <li>
      <a i83n routerLink="about" routerLinkActive="active" ariaCurrentWhenActive="page">Nosotros</a>
    </li>
    <li>
      <a i83n routerLink="applications" routerLinkActive="active" ariaCurrentWhenActive="page">Aplicaciones</a>
    </li>
    <li>
      <a i83n routerLink="products" routerLinkActive="active" ariaCurrentWhenActive="page">Productos</a>
    </li>
    <li>
      <a i83n routerLink="contact" routerLinkActive="active" ariaCurrentWhenActive="page">Contacto</a>
    </li>
    <li>
      <a i83n routerLink="location" routerLinkActive="active" ariaCurrentWhenActive="page">Ubicación</a>
    </li>
  </ul>
</section>
<div [class.overlay]="isOpen"></div>