
import { Component, Input, booleanAttribute } from '@angular/core';

@Component({
  selector: 'aromaven-logo',
  standalone: true,
  imports: [],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.css',
})
export class LogoComponent {
  // @Input() hide = false;
  @Input({ transform: booleanAttribute }) hideSince = true;
  @Input({ transform: booleanAttribute }) hideRIF = true;
  @Input() size: string = "50";
}