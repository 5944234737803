<style>
  .rotate {
    transform: rotate(90deg);
  }
</style>
<mat-toolbar>
  <a routerLink="home" class="aromaven-logo-link" ariaCurrentWhenActive="page">
    <aromaven-logo hideSince="true"></aromaven-logo>
  </a>
  <!-- <span class="example-spacer"></span> -->
  <ul class="links-container">
    <li>
      <a i83n routerLink="home" routerLinkActive="active" ariaCurrentWhenActive="page">Inicio</a>
    </li>
    <li>
      <a i83n routerLink="about" routerLinkActive="active" ariaCurrentWhenActive="page">Nosotros</a>
    </li>
    <li>
      <a i83n routerLink="applications" routerLinkActive="active" ariaCurrentWhenActive="page">Aplicaciones</a>
    </li>
    <li>
      <a i83n routerLink="products" routerLinkActive="active" ariaCurrentWhenActive="page">Productos</a>
    </li>
    <li>
      <a i83n routerLink="contact" routerLinkActive="active" ariaCurrentWhenActive="page">Contacto</a>
    </li>
    <li>
      <a i83n routerLink="location" routerLinkActive="active" ariaCurrentWhenActive="page">Ubicación</a>
    </li>
  </ul>
  <button (click)="toggleSideNav()" mat-icon-button class="menu-icon" aria-label="icon-button with menu icon">
    <mat-icon [class.rotate]="sidenavOpen">menu</mat-icon>
  </button>

</mat-toolbar>

<app-sidenav isOpen={{sidenavOpen}} (click)="toggleSideNav()" />