
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LogoComponent } from '../logo/logo.component';
import { SideNavComponent } from '../sidenav/sidenav.component';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    RouterLink, 
    RouterLinkActive, 
    MatSlideToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    LogoComponent,
    SideNavComponent,
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.css',
})
export class ToolbarComponent {
  sidenavOpen = false;
  toggleSideNav() {
    console.log("toggling sidenav", this);
    this.sidenavOpen = !this.sidenavOpen;
    return this;
  }
}