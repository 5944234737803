
export class Server {
  contact(message: string, email: string, subject: string): Promise<any> {
    return fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        message,
        email,
        subject,
      })
    });
  }
}