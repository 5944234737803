
import { Component } from '@angular/core';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { DividerComponent } from '../../divider/divider.component';


@Component({
  selector: 'home-page',
  standalone: true,
  imports: [MdbCarouselModule, DividerComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent {

  private readonly inceptionDate = 1976
  years = new Date().getFullYear() - this.inceptionDate;

}