import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideAnimationsAsync(), provideAnimations()]
};

export const contact = {
  email: 'aromaven@gmail.com',
  twitterLink: 'https://twitter.com/AromavenSabores',
  instagramLink: 'https://www.instagram.com/aromavensabores',
  facebookLink: 'https://www.facebook.com/aromavensabores',
  fax: '+58 212-2423944',
  phone: '+58 212-2423849',
};

export const productList = [
  "Vainillas",
  "Vainilla Caramel",
  "Mantecado",
  "Tutti Frutti",
  "Ciruela",
  "Frutos Salvajes",
  "Frutos Rojos",
  "Banana Fresa",
  "Colita",
  "Cola negra y roja",
  "Chocolates",
  "Chocolate Negro",
  "Cacao",
  "Café",
  "Piña Colada",
  "Malteada Chocolate",
  "Malta",
  "ChocoMalta",
  "Leche Condensada",
  "Arequipe",
  "Maple",
  "Naranja",
  "Naranja Amarga",
  "Toronja",
  "Tamarindo",
  "Mandarina",
  "Fresa",
  "Coco",
  "Cocada",
  "Cereza",
  "Frambuesa",
  "Guanabana",
  "Guayaba",
  "Almendra",
  "Almendras Amargas",
  "Ron Pasa",
  "Pasas",
  "Miel",
  "Caramelo",
  "Manzana Roja y Verde",
  "Durazno",
  "Melocotón",
  "Albaricoque",
  "Patilla",
  "Pera",
  "Piña",
  "Mango",
  "nettone",
  "Tiramisú",
  "Mora",
  "Parchita",
  "Canela",
  "Rosas",
  "Agua de Azahar",
  "ChocoAvellana",
  "Cookies and Cream",
  "Avellana",
  "Pasta de Avellana",
  "Pie Limón",
  "Pie Fresa",
  "Granadina",
  "Chicle",
  "Guaraná",
  "Frutas Tropicales",
  "Ponche Frutal",
  "Limón",
  "Lima",
  "Menta",
  "Yerba Buena",
  "Eucalipto",
  "Uva",
  "Maní",
  "Pistacho",
  "Nuez",
  "Galleta",
  "Cranberry",
  "Kiwi",
  "Flor de Jamaica",
  "Tamarindo",
  "Banana, Cambur",
  "Antiamargo",
  "Mantequilla",
  "Margarina",
  "Maíz Dulce",
  "Cachapa",
  "Trufa",
  "Picante",
  "Tocineta",
  "Ajo",
  "Nuez Moscada",
  "Pollo",
  "Carne",
  "Clavo",
  "Kétchup / Tomate",
  "Albahaca",
  "Hinojo",
  "Jengibre",
  "BBQ",
  "Queso Cheddar",
  "Queso Crema",
  "Yogurt",
  "Ahumado, Humo",
  "Ginebra, Gin",
  "Anís",
  "Vodka",
  "Brandy",
  "Whisky",
  "Ron / Ron Jamaica",
  "Tequila",
  "Cocuy",
  "Roble",
  "Sustituto de Vainillina",
  "Aroma Té Negro",
  "Aroma Té Verde",
  "Aroma Leche",
  "Aroma Papelón ",
];

export const companyName = 'Aromaven Sabores, C.A.';
