<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style> 
  .category-list > li {
    list-style-type: disclosure-closed;
    cursor: pointer;
  }

  .category-list > li:hover {
    color: black;
  }

  .category-list > li.open {
    list-style-type: disclosure-open;
    color: black;
  }

  li.open .product-list {
    display: flex;;
    align-items: center;
  }

  li.open > ul {
    display: block;
  }

  .product-list {
    display: flex;
    list-style-type: circle;
    display: none;
    cursor: default;
    margin: 10px 0px 10px 0px;
  }

  .product-list img {
    border-radius: 50%;
    max-height: 100px;
    margin: 10px;
    border: 3px solid black;
    filter: brightness(0.9);
  }

  h3 {
    margin-top: 25px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: decimal;
  }

  ul li {
    margin: 20px;
    min-width: 150px;
    border-right: 1px solid black;
  }

</style>

<main class="main page">
  <section class="content">
    <h2 i83n>Nuestra gama de productos incluye</h2>
    <p>Contamos con líneas de producción exclusiva por cliente de escencias, aromas, sabores en polvo y líquidos de:</p> 
    <ul>
      <li *ngFor="let product of products;">
        {{product}}
      </li>
    </ul>
  </section>
</main>
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder  * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
