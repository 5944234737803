import { Component, Input, booleanAttribute } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { contact } from '../app.config';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.css',
})
export class SideNavComponent {
  @Input({ transform: booleanAttribute }) isOpen = true;
  social = contact
 
}