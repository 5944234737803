import { Component } from '@angular/core';

@Component({
  selector: 'location-page',
  standalone: true,
  imports: [],
  templateUrl: './location.component.html',
  styleUrl: './location.component.css',
})
export class LocationComponent {
}