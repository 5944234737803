<style>

  iframe {
    width: 100%;
    min-height: 80vh;
  }

  address {
    margin: 0px;
  }

</style>
<main class="main page">
  <section class="content">
    <h2 i83n>Ubicación</h2>
    <ul>
      <li>
        Aromaven Sabores, C.A
      </li>
      <li>
        <address> 
          <!-- Calle 10, edificio San Gabriel Piso 4 Oficina 11. -->
          Av. 4ta Transversal de Monte Cristo. Edificio Axxa, Piso 1 Urb. 
        </address>
      </li>
      <li>
        <address>
          Los dos Caminos, Caracas, Venezuela.
        </address>
      </li>
      <li>
        Teléfonos: +58212-2423849
      </li>
      <li>
        Fax +58212-2423944
      </li>
      <li>
        email: aromaven&#64;gmail.com
      </li>
      <li>
        Rif:J- 29546429-0
      </li>
    </ul>
    <div class="location">
      <div>
        <iframe width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=-66.82625055313112%2C10.495708783582122%2C-66.82459294795991%2C10.49723315759297&amp;layer=mapnik" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/#map=19/10.49647/-66.82542">View Larger Map</a></small>
      </div>
    </div>
  </section>
</main>