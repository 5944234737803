import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './circle.component.html',
  styleUrl: './circle.component.css'
})
export class CircleComponent {
  @Input() src: string = "assets/products/aceite.webp";
  @Input() size: string = "150px";
  @Input() title: string = "This is a test";
  styles = {
    height: this.size,
    width: this.size
  };

  imageStyles = {
    height: this.size,
    width: this.size
  };
}
