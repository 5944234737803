<style>
  .contact-form {
    display: flex;
    /* display: none; */
    flex-direction: column;
    /* max-width: 700px; */
    /* width: 45%; */
    padding-right: 10px;
    min-width: 300px;
  }

  .contact-area {
    display: none;
    /* display: flex; */
    /* min-height: 200px; */
  }
  
  .contact-area > section {
    display: none;
  }


  .contact-area > section {
    width: 50%;
  }
  
  .contact-twitter {
    padding-top: 25px;
  }

  textarea {
    min-height: 150px;
  }

  button {
    background-color: var( --color-brand)!important;
    color: white!important;
  }

  iframe {
    width: 100%;
    min-height: 80vh;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .elfsight-form-container {
    min-height: 300px;;
  }

  @media screen and (max-width: 700px) {
    .contact-area {
      flex-direction: column;
      width: 100%;
    }
    .contact-area > section {
      width: 100%;
    }
  }

</style>

<main class="main page">
  <div class="contact-area content">
    <section>
      <h2 i83n>Contacto</h2>
      <form *ngIf="submitted === false" class="contact-form">
        <mat-form-field class="contact-full-width">
          <mat-label>Email</mat-label>
          <input type="email" matInput placeholder="Ex. miemail@dominio.com" [formControl]="emailFormControl" [errorStateMatcher]="matcher">
            @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
              <mat-error i83n>Por favor introduzca un email correcto</mat-error>
            }
            @if (emailFormControl.hasError('required')) {
              <mat-error>Email es <strong>requerido</strong></mat-error>
            }
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label i83n>Asunto</mat-label>
          <input type="text" matInput [formControl]="titleFormControl" />
            @if (titleFormControl.hasError('required')) {
              <mat-error>Asunto es <strong>requerido</strong></mat-error>
            }
        </mat-form-field>
        
        <mat-form-field class="example-full-width">
          <mat-label i83n>Dejanos un mensaje</mat-label>
          <textarea matInput [formControl]="messageFormControl"></textarea>
        </mat-form-field>

        <button mat-flat-button i83n (click)="onSubmitt()">Enviar</button>
      </form>
      <h3 *ngIf="submitted" i83n>Muchas gracias por ponerte en contacto con nosotros.</h3>
    </section>  
    <section class="contact-twitter">
      <!-- <iframe class="timeline-tweets" src="https://syndication.twitter.com/srv/timeline-profile/screen-name/AromavenSabores?dnt=false&embedId=twitter-widget-6&features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&frame=false&hideBorder=false&hideFooter=false&hideHeader=false&hideScrollBar=false&lang=en&maxHeight=300px&origin=https%3A%2F%2Fpublish.twitter.com%2F%23&sessionId=808ab669756e94985dae0c7682f15c42eeb8c1e9&showHeader=true&showReplies=false&transparent=false&widgetsVersion=2615f7e52b7e0%3A1702314776716" frameborder="0"></iframe> -->
    </section>
  </div>
  

  <div class="elfsight-form-container"> 
    <div class="elfsight-app-1720877f-ae87-4b36-9e65-6aaabe5ba663" data-elfsight-app-lazy></div>
  </div>
  <section>
    <div>
      <iframe width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=-66.82625055313112%2C10.495708783582122%2C-66.82459294795991%2C10.49723315759297&amp;layer=mapnik" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/#map=19/10.49647/-66.82542">View Larger Map</a></small>
    </div>
  </section>
</main>
