<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style> </style>

<main class="main page">
  <section class="content">
    <h2 i83n>Hemos sido inspirador de los sentidos desde 1976.</h2>
    <p i83n>Aromaven Sabores es una empresa fundada por el Dr. Pedro Bernal Guerris en el ano 1976 en la ciudad de Caracas - Venezuela, para ofrecer soluciones innnovadoras que apoyan la creación y la evolución de productos alimenticios y bebidas a través de una experiencia integrada en el desarrollo, aplicación y fabricación de un sabor y aroma auténtico.</p>
    <p i83n>Aromaven es reconocido como fabricante lider en Venezuela de aromas, esencias, sabores y materia prima para bebidas y alimentos prestando servicios a empresas del mercado medio, con la misma devoción que sus clientes trasnacionales. Durante más de 45 años, nos hemos mantenido comprometidos con los más altos estandares, sin comprometer nuestros principios.</p>
    <!-- <p i83n>El talento y el entusiasmo es un recurso que prospera en un ambiente de creatividad, espíritu de cooperación y crecimiento mutuo. Somos reconocidos por ser un proveedor confiable que sirve a una diversa base de clientes.  </p> -->
    
  </section>
</main>
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder  * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
